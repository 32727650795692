import { checkWebInIframe, POST } from "utils";
import { loginUrl, resetPassword, propertyURL } from "constants/urls";
import { RollbarErrorTracking } from "utils/rollbar";

export const serverErrors = async ({
  response,
  request,
  message,
  config,
  response: { data, data: { error = {} } = {}, status } = {},
} = {}) => {
  console.log("config url", config.url);
  switch (true) {
    case status === 401 && config.url !== loginUrl:
      if (config.url === resetPassword) {
        return returnErrorObject(
          true,
          { ...error, status },
          error.message,
          false,
          status
        );
      }
      if (config.url.includes(propertyURL) && config.url.includes("?token=")) {
        const regex =
          /\/properties\/([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})\?token=([0-9a-z]+)/g;
        const matches = regex.exec(config.url);
        const res = await POST("/users/landlord/validate-token", {
          token: matches[2],
          propertyUuid: matches[1],
        });
        if (!res) {
          window.localStorage.removeItem("authUser");
          return window.location.replace(
            checkWebInIframe ? "/start-search" : "/"
          );
        }
        if (res?.data?.alreadyRegistered) {
          return window.location.replace(`/property/${matches[1]}/get-support`);
        } else {
          return window.location.replace(
            `/landlord-register?email=${res.data.email}&token=${matches[2]}&firstName=${res.data.firstName}&lastName=${res.data.lastName}&propertyUuid=${matches[1]}`
          );
        }
      } else if (
        (config.url.includes(propertyURL) && !config.url.includes("?token=")) ||
        config.url.includes("/start-search")
      ) {
        window.localStorage.removeItem("authUser");
        return window.location.replace(
          checkWebInIframe ? "/start-search" : "/"
        );
      }
    case response && data && status >= 400 && status <= 499:
      return returnErrorObject(
        true,
        { ...error, status },
        error.message,
        false,
        status
      );

    case response && status >= 500:
      RollbarErrorTracking.logErrorInRollbar(
        `Error come on this request ${request.responseURL}, message:${message}`
      );
      return returnErrorObject(
        false,
        null,
        "Internal Server Error",
        true,
        status
      );

    case !response && request:
      return returnErrorObject(false, null, request, false, status);

    case !!message:
      return returnErrorObject(false, { message }, message, false, status);

    default:
      return returnErrorObject(false, null, config, false, status);
  }
};

const returnErrorObject = (
  clientError,
  errorDetails,
  message,
  serverError,
  status
) => ({
  clientError,
  errorDetails,
  message,
  serverError,
  status,
});
