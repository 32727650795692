import Typography from "v2/components/Typography/Typography";
import styles from "./SharePropertyModal.module.css";
import { ReactComponent as Cross } from "images/crossIcon.svg";
import { Button, Input, Row2Items } from "v2/components/Form";
import { useFormik } from "formik";
import * as yup from "yup";
import { sendApprovalEmail } from "Apis";
import { useState } from "react";
import { mixPanel } from "utils";

export default function SharePropertyModal({
  onClose: closeModal,
  propertyDetails,
}) {
  const [loading, setLoading] = useState(false);
  const handleSubmitPropertyReport = (data) => {
    mixPanel.track("Property Shared", {
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      propertyUuid: data.propertyUuid,
    });
    setLoading(true);
    const payload = {
      emailBody: `The following property has been shared with you. Please ensure you create/login uising the email that this report was shared with (email) to ensure that you can view this report.`,
      subject: "A property has been shared with you!",
      packageId: data.packageId,
      landlords: [
        {
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email,
        },
      ],
    };

    sendApprovalEmail(payload, data.propertyUuid).then(() => {
      closeModal();
    });
  };

  const SharePropertyValidationSchema = yup.object().shape({
    email: yup
      .string()
      .email("Must be a valid Email")
      .required("Email is Required!"),
    firstName: yup.string().required("First Name is Required!"),
    lastName: yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      firstName: "",
      lastName: "",
      propertyUuid: propertyDetails.uuid,
      packageId: propertyDetails.packageId,
      propertyDetails,
    },
    onSubmit: (event) => handleSubmitPropertyReport(event),
    validationSchema: SharePropertyValidationSchema,
  });

  return (
    <>
      <header className={styles.header}>
        <span className={styles.title}>Share property report</span>
        <div className={styles.close} onClick={closeModal}>
          <Cross />
        </div>
      </header>
      <div className={styles.container}>
        <Typography as="header">
          Who do you want to share the property with?
        </Typography>
        <Typography as="note">
          Note: The account you are sharing this property report with will have
          to match the email you input here
        </Typography>
        <Row2Items>
          <Input
            label="First Name"
            type="text"
            name="firstName"
            id="firstName"
            placeholder="First Name"
            value={formik.values.firstName}
            error={formik.errors.firstName}
            onChange={formik.handleChange}
          />
          <Input
            label="Last Name"
            type="text"
            name="lastName"
            id="lastName"
            placeholder="Last Name"
            value={formik.values.lastName}
            error={formik.errors.lastName}
            onChange={formik.handleChange}
          />
        </Row2Items>
        <Input
          label="Email Address"
          type="email"
          name="email"
          id="email"
          placeholder="Recipient Email"
          value={formik.values.email}
          error={formik.errors.email}
          onChange={formik.handleChange}
        />
        <div className={styles.buttons}>
          <Button
            actionName="Cancel"
            variant="secondary"
            onClick={closeModal}
          />
          <Button
            actionName={"Share Property"}
            onClick={formik.handleSubmit}
            loading={loading}
          />
        </div>
      </div>
    </>
  );
}
